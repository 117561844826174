import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Image = () => {
    return (
        <div className="relative">
            <StaticImage
                src="../images/Library-7-2.jpg"
                alt="Students studying in the Library"
                loading="eager"
                layout="fullWidth"
                className="hero-container"
                imgClassName="hero-image"
                backgroundColor="#000000"
                placeholder="none"
            />
            <div className="absolute bottom-8">
                <div className="w-screen p-2 text-center">
                    <h1 className="text-4xl text-white font-light tracking-wider">
                        Southwestern Adventist University Catalog
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default Image
