import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Search from '../components/search'
import Hero from '../components/hero2'
import { useFlexSearch } from 'react-use-flexsearch'
import PropTypes from 'prop-types'

const IndexPage = ({
    data: {
        localSearchPages: { index, store }
    }
}) => {
    const { search } =
        typeof window !== `undefined` ? window.location : { search: null }
    const query = new URLSearchParams(search).get('s')
    const [searchQuery, setSearchQuery] = useState(query || '')
    const results = useFlexSearch(searchQuery, index, store)

    return (
        <Layout>
            <SEO title="" />
            <Hero />

            <div className="container mx-auto py-4 px-2 xl:px-0 grid gap-4 md:grid-cols-2 xl:grid-cols-3 text-xl tracking-wider">
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/supplement_2017-2018"
                >
                    Undergraduate Bulletin Supplement 2017-2018
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2017-2018"
                >
                    Graduate Bulletin 2017-2018
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2017-2018"
                >
                    Undergraduate Bulletin 2017-2018
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/supplement_2016-2017"
                >
                    Undergraduate Bulletin Supplement 2016-2017
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2016-2017"
                >
                    Graduate Bulletin 2016-2017
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2016-2017"
                >
                    Undergraduate Bulletin 2016-2017
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/supplement_2015-2016"
                >
                    Undergraduate Bulletin Supplement 2015-2016
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2015-2016"
                >
                    Graduate Bulletin 2015-2016
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2015-2016"
                >
                    Undergraduate Bulletin 2015-2016
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/supplement_2014-2015"
                >
                    Undergraduate Bulletin Supplement 2014-2015
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2014-2015"
                >
                    Graduate Bulletin 2014-2015
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2014-2015"
                >
                    Undergraduate Bulletin 2014-2015
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/supplement_2013-2014"
                >
                    Graduate Bulletin Supplement 2013-2014
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/supplement_2013-2014"
                >
                    Undergraduate Bulletin Supplement 2013-2014
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2013-2014"
                >
                    Graduate Bulletin 2013-2014
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2013-2014"
                >
                    Undergraduate Bulletin 2013-2014
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2012-2013"
                >
                    Graduate Bulletin 2012-2013
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2012-2013"
                >
                    Undergraduate Bulletin 2012-2013
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2011-2012"
                >
                    Graduate Bulletin 2011-2012
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2011-2012"
                >
                    Undergraduate Bulletin 2011-2012
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/graduate/2010-2011"
                >
                    Graduate Bulletin 2010-2011
                </Link>
                <Link
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    to="/undergraduate/2010-2011"
                >
                    Undergraduate Bulletin 2010-2011
                </Link>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2009-2010.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2009-2010
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2009-2010.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2009-2010
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2008-2009.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2008-2009
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2008-2009.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2008-2009
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2007-2008.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2007-2008
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2007-2008.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2007-2008
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2006-2007.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2006-2007
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2006-2007.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2006-2007
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2005-2006.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2005-2006
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2005-2006.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2005-2006
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2004-2005.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2004-2005
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2004-2005.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2004-2005
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2003-2004.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2003-2004
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2003-2004.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2003-2004
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2002-2003.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2002-2003
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2002-2003.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2002-2003
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Graduate-Bulletin-2001-2002.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Graduate Bulletin 2001-2002
                </a>
                <a
                    className="border text-primary py-4 px-2 flex justify-center items-center text-center shadow hover:shadow-xl hover:bg-secondary hover:text-white focus:outline-none focus:ring focus:ring-accent focus:bg-secondary focus:text-white focus:shadow-xl transition duration-300 rounded"
                    href="/pdfs/Undergraduate-Bulletin-2001-2002.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Undergraduate Bulletin 2001-2002
                </a>
            </div>

            <section className="bg-dark text-gray-100">
                <div className="container mx-auto pl-1 pt-1 pr-1 pb-2 relative">
                    <Search
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="fill-current text-gray-400 h-10 w-10 absolute top-4 left-3"
                    >
                        <path d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z" />
                    </svg>
                </div>
                {results && results.length > 0 && (
                    <div className="container mx-auto p-2">
                        <div className="grid gap-4">
                            {results.map((result) => (
                                <Link
                                    key={result.slug}
                                    to={result.slug}
                                    className="group border p-2 shadow hover:bg-secondary focus:outline-none focus:ring focus:ring-accent focus:bg-secondary"
                                >
                                    <div className="text-2xl tracking-wider">
                                        {result.title}
                                    </div>
                                    <div className="text-lg tracking-wider text-gray-300 group-hover:text-gray-100 group-focus:text-gray-100">
                                        {result.parentName}
                                    </div>
                                    <div className="text-gray-400 group-hover:text-gray-100 group-focus:text-gray-100">
                                        {result.excerpt}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </section>
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.object
}

export default IndexPage

export const pageQuery = graphql`
    query {
        localSearchPages {
            index
            store
        }
    }
`
