import React from 'react'
import PropTypes from 'prop-types'
import './search.css'

const SearchBar = ({ searchQuery, setSearchQuery }) => (
    <div>
        <label htmlFor="search-input">
            <span className="visually-hidden">Search</span>
        </label>
        <input
            value={searchQuery}
            onInput={(e) => setSearchQuery(e.target.value)}
            type="text"
            id="search-input"
            placeholder="Search"
            name="s"
            className="w-full bg-dark border-none py-4 pl-14 text-3xl tracking-wider font-semibold focus:outline-none focus:ring-1 focus:ring-accent"
            autoComplete="false"
        />
    </div>
)

SearchBar.propTypes = {
    searchQuery: PropTypes.string,
    setSearchQuery: PropTypes.func
}

export default SearchBar
